import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from "react-ga4";

import CatchmentSelectedCheck from './dash/general_components/CatchmentSelectedCheck';
import CsvDownloads from './dash/pages/CsvDownloads/CsvDownloads';
import Dashboard from './dash/pages/Dashboard/Dashboard';
import Login from './dash/pages/Login/Login';
import Logout from './dash/pages/Logout/Logout';
import MonitorningDataIssues from './dash/pages/Admin/MonitoringDataIssues/MonitoringDataIssues';
import NotFound from './dash/pages/NotFound/NotFound';
import PrivateRoute from './dash/pages/PrivateRoute/PrivateRoute';
import RastersDownloads from './dash/pages/Rasters/RastersDownload';
import UserInformationStorage from './dash/general_utils/userinformationstorage';

import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import './style/app.scss';

// Google analytics ID
const GA_MEASUREMENT_ID ='G-MTH2L8WR09';

function App() {

  const [userId, setUserId] = useState(UserInformationStorage.get_user_information('user'));

  useEffect(() => {
    ReactGA.initialize(GA_MEASUREMENT_ID);
    ReactGA.set({ userId: userId});

  }, [userId]);

  return (
    < BrowserRouter>
      <Routes>
        <Route path="" element={<Login setUserId = {setUserId}/>} />
        <Route path="logout" element={<Logout/>}/>
        <Route path="dashboard"
          element={
            <PrivateRoute>
              <Dashboard/>
            </PrivateRoute>
          }
        />
        <Route path="rasters"
          element={
            <PrivateRoute>
              <CatchmentSelectedCheck>
                <RastersDownloads/>
              </CatchmentSelectedCheck>
            </PrivateRoute>
          }
        />
        <Route path="downloads"
          element={
            <PrivateRoute>
              <CsvDownloads/>
            </PrivateRoute>
          }
        />
        <Route path="monitoring"
          element={
            <PrivateRoute>
              <MonitorningDataIssues/>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ BrowserRouter>
  );
}

export default App;