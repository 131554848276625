export default class YearlySpatialAux {

    getWaterYearStartAndEndFromGeoJson(catchmentGeoJson) {
        let latitude = this.getLatitudeFromCoordinates(this.getCoordinatesFromGeoJSON(catchmentGeoJson[0]));
        if (this.isNorthHemisphere(latitude)) {
            this.water_year_start_month_and_day = "10-01";
            this.water_year_end_month_and_day = "09-30";
        } else {
            this.water_year_start_month_and_day = "07-01";
            this.water_year_end_month_and_day = "06-30";
        }
    };

    getLatitudeFromCoordinates(coordinates) {
        return coordinates[1];
    }

    isNorthHemisphere(lat) {
        return lat >= 0;
    }

    getCoordinatesFromGeoJSON(geojson) {
        if (geojson.geometry.type === 'Polygon') {
          return geojson.geometry.coordinates[0][0];
        } else if (geojson.geometry.type === 'MultiPolygon') {
          return geojson.geometry.coordinates[0][0][0];
        } else return [];
    }

}