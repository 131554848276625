import React, { useEffect, useState, useMemo } from 'react';
import { Button, Container } from 'react-bootstrap';
import { MdOutlineDashboard } from 'react-icons/md';

import ContactParagraph from '../../general_components/ContactParagraph';
import TooltipCustom from '../../general_components/TooltipCustom';
import NavBar from '../../general_components/NavBar';

import { addButtonDownload, getDownloadsFromApi } from './utils/csvDownloads_aux';
import DateManager from '../../general_utils/datemanager';

import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

function CsvDownloads() {

  const [rowData, setRowData] = useState([]);

  useEffect(() => {

    const datemanager = new DateManager();
    
    getDownloadsFromApi().then(response => {
      let data = response.data.map(entry => {
        const date = datemanager.ISOStringToDate(entry["download_date"]);
        return { ...entry, "download_date": date };
      });
      setRowData(data);
    })

  }, [])

  const columnDefs = [
    {
      filter: 'agDateColumnFilter',
      headerName: 'Date',
      field: 'download_date',
      sortable: true,
      cellRenderer: p => DateManager.dateToLocalDateString(p.data.download_date),
    },
    { filter: 'agTextColumnFilter', headerName: 'File Name', field: "download_name" },
    { headerName: 'Download file', cellRenderer: p => addButtonDownload(p.data.download_name) },
  ];

  const defaultColDef = useMemo(() => {
    return {
      floatingFilter: true,
      flex: 1,
      resizable: false,
    }
  }, []);

  return (
    <>
      <NavBar>
        <TooltipCustom
          placement={'bottom'}
          text={"Back to dashboard"}>
          <Button variant='wegaw-darkblue' href='/dashboard'>
            <MdOutlineDashboard
              size={22}
            />
          </Button>
        </TooltipCustom>
      </NavBar>
      <Container className={`text-center mt-3 mb-3 tableContainer`}>
        <div className="ag-theme-quartz" >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={[10, 25, 50]}
            domLayout={'autoHeight'}
          />
        </div>
      </Container>
      <ContactParagraph />
    </>
  )
};

export default CsvDownloads;