export default class DateManager {

    constructor() {
        this.daysInAYear = 365;
        this.maxNumYearsWithData = 50;
        this.numOfTotalDays = this.maxNumYearsWithData * this.daysInAYear
    }

    getToFrom(numDays, waterYearStart = null) {

        let to = this.ISOStringToDate(new Date().toISOString());
        let from;
        const currentDate = new Date();
    
        if(waterYearStart) { 
            from = this.unixTimestampToDatetime(this.substractDaysToUnixTimestamp(currentDate, numDays)).getFullYear() + "-" + waterYearStart;
        }
        else {
            from = this.ISOStringToDate(this.unixTimestampToDatetime(this.substractDaysToUnixTimestamp(currentDate, numDays)).toISOString());
        }
        return [to, from]
    }
      
    getArrayOfDatesFromStartAndEndDates = (start_date, end_date) => {
        let arrayOfDates = [];
        let dateMove = new Date(start_date);
        let tmpStartDate = start_date;
        while (tmpStartDate < end_date) {
          tmpStartDate = this.ISOStringToDate(dateMove.toISOString());
          arrayOfDates.push(tmpStartDate);
          dateMove.setDate(dateMove.getDate() + 1);
        };
        return arrayOfDates;
    }

    transformStringDatetimeToDate(stringDatetime) { 
        return this.ISOStringToDate(this.dateUTCAdjustment(stringDatetime).toISOString());
    }

    dateUTCAdjustment(fullStringDate) {
        let millisecondsInAMinute = 60000
        let currentUTCDateInMilliseconds = Date.parse(fullStringDate)
        let offsetMilliseconds = fullStringDate.getTimezoneOffset() * millisecondsInAMinute
        let signOfOffset = offsetMilliseconds > 0 ? 1 : -1;
        let dayInDatetimeFormat = new Date(currentUTCDateInMilliseconds + signOfOffset * offsetMilliseconds);
        return dayInDatetimeFormat
    }

    ISOStringToDate(datetime) { 
        return datetime.split('T')[0];
    }

    substractDaysToUnixTimestamp(unix_timestamp, num_days) {
        return unix_timestamp.setDate(unix_timestamp.getDate() - num_days);
    }

    unixTimestampToDatetime(unix_timestamp) { 
        return new Date(unix_timestamp);
    }

    static getStartDateEndDate = (period_start, period_end, year=2004) => {
        const startDate = (year - 1) + "-" + period_start;
        const endDate = year + "-" + period_end;
        return[startDate, endDate];
    }

    static getWaterYearsArray = (water_year_start_month_and_day, numYears = 50) => {
        // Init max year (biggest water year number for the available data)
        let maxYear;
        // Get current date
        const currentDate = new Date();
        // Get the current year as the temporal maximum year
        let currentYear = currentDate.getFullYear();
        // Get the month and the day when the water year starts
        let [ water_year_month, water_year_day ] = water_year_start_month_and_day.split("-")
        // Create a water year start date for the current year with the temporal maximum year (month go from 0 to 11). The date can be before or after the current date.
        const waterYearStartDateForCurrentYear = new Date(currentYear, water_year_month - 1 , water_year_day);
        // Check if the current date is bigger than the temporal water year start date, if so, add one to the max year
        // TODO: issue-> the dash has to have data for the current water ear to work
        if (currentDate >= waterYearStartDateForCurrentYear) { 
            maxYear = currentYear + 1;
        } else {
            maxYear = currentYear;
        }
        // For the min year, we take the max year - the number of years (-1), as max year represent always the biggest number on the water year
        const minYear = maxYear - (numYears - 1);
        let years = [];
        // Produce an array of years from the min year to the max year
        for (let year = maxYear; year >= minYear; year--) {
          years.push(year);
        }
        return years;
    };
    
    static getCurrentDatetime() {
        const today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        return date + 'T' + time;
        // example of format 10-10-2022T16:30:30
    };

    static filterDataByDateRange(data, fromDate, toDate) {
      
        // Example filtering range
        let startDate = new Date(fromDate);
        let endDate = new Date(toDate);
      
        // Filter data based on date range
        let filteredData = data.dates
            .map((date, index) => ({ date: new Date(date), value: data.values[index] }))
            .filter(entry => entry.date >= startDate && entry.date <= endDate);
      
        // Unzip the filtered data
        let filteredDates = filteredData.map(entry => entry.date.toISOString().split('T')[0]);
        let filteredValues = filteredData.map(entry => entry.value);
        return [filteredDates, filteredValues]
      }

    static isTwentyNineFebruary(day) { 
        let monthAndDay = day;
        if(day.length === 10) {
            monthAndDay = day.substr(day.length - 5)
        }
        return monthAndDay === "02-29";
    }

    static isDateAmongDates(date, start_date, end_date) {
        return date >= start_date && date < end_date;
    }

    static replaceYearFromStrDate(date, year) { 
        return date.replace(date.slice(0, 4), year);
    }

    static dateToLocalDateString(strDate) {
        return new Date(strDate).toLocaleDateString()
    }

    static getLocaleDateString() {
        const formats = {
          "af-ZA": "yyyy/MM/dd",
          "am-ET": "d/M/yyyy",
          "ar-AE": "dd/MM/yyyy",
          "ar-BH": "dd/MM/yyyy",
          "ar-DZ": "dd-MM-yyyy",
          "ar-EG": "dd/MM/yyyy",
          "ar-IQ": "dd/MM/yyyy",
          "ar-JO": "dd/MM/yyyy",
          "ar-KW": "dd/MM/yyyy",
          "ar-LB": "dd/MM/yyyy",
          "ar-LY": "dd/MM/yyyy",
          "ar-MA": "dd-MM-yyyy",
          "ar-OM": "dd/MM/yyyy",
          "ar-QA": "dd/MM/yyyy",
          "ar-SA": "dd/MM/yy",
          "ar-SY": "dd/MM/yyyy",
          "ar-TN": "dd-MM-yyyy",
          "ar-YE": "dd/MM/yyyy",
          "arn-CL": "dd-MM-yyyy",
          "as-IN": "dd-MM-yyyy",
          "az-Cyrl-AZ": "dd.MM.yyyy",
          "az-Latn-AZ": "dd.MM.yyyy",
          "ba-RU": "dd.MM.yy",
          "be-BY": "dd.MM.yyyy",
          "bg-BG": "dd.M.yyyy",
          "bn-BD": "dd-MM-yy",
          "bn-IN": "dd-MM-yy",
          "bo-CN": "yyyy/M/d",
          "br-FR": "dd/MM/yyyy",
          "bs-Cyrl-BA": "d.M.yyyy",
          "bs-Latn-BA": "d.M.yyyy",
          "ca-ES": "dd/MM/yyyy",
          "co-FR": "dd/MM/yyyy",
          "cs-CZ": "d.M.yyyy",
          "cy-GB": "dd/MM/yyyy",
          "da-DK": "dd-MM-yyyy",
          "de-AT": "dd.MM.yyyy",
          "de-CH": "dd.MM.yyyy",
          "de-DE": "dd.MM.yyyy",
          "de-LI": "dd.MM.yyyy",
          "de-LU": "dd.MM.yyyy",
          "dsb-DE": "d. M. yyyy",
          "dv-MV": "dd/MM/yy",
          "el-GR": "d/M/yyyy",
          "en-029": "MM/dd/yyyy",
          "en-AU": "d/MM/yyyy",
          "en-BZ": "dd/MM/yyyy",
          "en-CA": "dd/MM/yyyy",
          "en-GB": "dd/MM/yyyy",
          "en-IE": "dd/MM/yyyy",
          "en-IN": "dd-MM-yyyy",
          "en-JM": "dd/MM/yyyy",
          "en-MY": "d/M/yyyy",
          "en-NZ": "d/MM/yyyy",
          "en-PH": "M/d/yyyy",
          "en-SG": "d/M/yyyy",
          "en-TT": "dd/MM/yyyy",
          "en-US": "M/d/yyyy",
          "en-ZA": "yyyy/MM/dd",
          "en-ZW": "M/d/yyyy",
          "es-AR": "dd/MM/yyyy",
          "es-BO": "dd/MM/yyyy",
          "es-CL": "dd-MM-yyyy",
          "es-CO": "dd/MM/yyyy",
          "es-CR": "dd/MM/yyyy",
          "es-DO": "dd/MM/yyyy",
          "es-EC": "dd/MM/yyyy",
          "es-ES": "dd/MM/yyyy",
          "es-GT": "dd/MM/yyyy",
          "es-HN": "dd/MM/yyyy",
          "es-MX": "dd/MM/yyyy",
          "es-NI": "dd/MM/yyyy",
          "es-PA": "MM/dd/yyyy",
          "es-PE": "dd/MM/yyyy",
          "es-PR": "dd/MM/yyyy",
          "es-PY": "dd/MM/yyyy",
          "es-SV": "dd/MM/yyyy",
          "es-US": "M/d/yyyy",
          "es-UY": "dd/MM/yyyy",
          "es-VE": "dd/MM/yyyy",
          "et-EE": "d.MM.yyyy",
          "eu-ES": "yyyy/MM/dd",
          "fa-IR": "MM/dd/yyyy",
          "fi-FI": "d.M.yyyy",
          "fil-PH": "M/d/yyyy",
          "fo-FO": "dd-MM-yyyy",
          "fr-BE": "d/MM/yyyy",
          "fr-CA": "yyyy-MM-dd",
          "fr-CH": "dd.MM.yyyy",
          "fr-FR": "dd/MM/yyyy",
          "fr-LU": "dd/MM/yyyy",
          "fr-MC": "dd/MM/yyyy",
          "fy-NL": "d-M-yyyy",
          "ga-IE": "dd/MM/yyyy",
          "gd-GB": "dd/MM/yyyy",
          "gl-ES": "dd/MM/yy",
          "gsw-FR": "dd/MM/yyyy",
          "gu-IN": "dd-MM-yy",
          "ha-Latn-NG": "d/M/yyyy",
          "he-IL": "dd/MM/yyyy",
          "hi-IN": "dd-MM-yyyy",
          "hr-BA": "d.M.yyyy.",
          "hr-HR": "d.M.yyyy",
          "hsb-DE": "d. M. yyyy",
          "hu-HU": "yyyy. MM. dd.",
          "hy-AM": "dd.MM.yyyy",
          "id-ID": "dd/MM/yyyy",
          "ig-NG": "d/M/yyyy",
          "ii-CN": "yyyy/M/d",
          "is-IS": "d.M.yyyy",
          "it-CH": "dd.MM.yyyy",
          "it-IT": "dd/MM/yyyy",
          "iu-Cans-CA": "d/M/yyyy",
          "iu-Latn-CA": "d/MM/yyyy",
          "ja-JP": "yyyy/MM/dd",
          "ka-GE": "dd.MM.yyyy",
          "kk-KZ": "dd.MM.yyyy",
          "kl-GL": "dd-MM-yyyy",
          "km-KH": "yyyy-MM-dd",
          "kn-IN": "dd-MM-yy",
          "ko-KR": "yyyy. MM. dd",
          "kok-IN": "dd-MM-yyyy",
          "ky-KG": "dd.MM.yy",
          "lb-LU": "dd/MM/yyyy",
          "lo-LA": "dd/MM/yyyy",
          "lt-LT": "yyyy.MM.dd",
          "lv-LV": "yyyy.MM.dd.",
          "mi-NZ": "dd/MM/yyyy",
          "mk-MK": "dd.MM.yyyy",
          "ml-IN": "dd-MM-yy",
          "mn-MN": "yy.MM.dd",
          "mn-Mong-CN": "yyyy/M/d",
          "moh-CA": "M/d/yyyy",
          "mr-IN": "dd-MM-yyyy",
          "ms-BN": "dd/MM/yyyy",
          "ms-MY": "dd/MM/yyyy",
          "mt-MT": "dd/MM/yyyy",
          "nb-NO": "dd.MM.yyyy",
          "ne-NP": "M/d/yyyy",
          "nl-BE": "d/MM/yyyy",
          "nl-NL": "d-M-yyyy",
          "nn-NO": "dd.MM.yyyy",
          "nso-ZA": "yyyy/MM/dd",
          "oc-FR": "dd/MM/yyyy",
          "or-IN": "dd-MM-yy",
          "pa-IN": "dd-MM-yy",
          "pl-PL": "dd.MM.yyyy",
          "prs-AF": "dd/MM/yy",
          "ps-AF": "dd/MM/yy",
          "pt-BR": "d/M/yyyy",
          "pt-PT": "dd-MM-yyyy",
          "qut-GT": "dd/MM/yyyy",
          "quz-BO": "dd/MM/yyyy",
          "quz-EC": "dd/MM/yyyy",
          "quz-PE": "dd/MM/yyyy",
          "rm-CH": "dd/MM/yyyy",
          "ro-RO": "dd.MM.yyyy",
          "ru-RU": "dd.MM.yyyy",
          "rw-RW": "M/d/yyyy",
          "sa-IN": "dd-MM-yyyy",
          "sah-RU": "MM.dd.yyyy",
          "se-FI": "d.M.yyyy",
          "se-NO": "dd.MM.yyyy",
          "se-SE": "yyyy-MM-dd",
          "si-LK": "yyyy-MM-dd",
          "sk-SK": "d. M. yyyy",
          "sl-SI": "d.M.yyyy",
          "sma-NO": "dd.MM.yyyy",
          "sma-SE": "yyyy-MM-dd",
          "smj-NO": "dd.MM.yyyy",
          "smj-SE": "yyyy-MM-dd",
          "smn-FI": "d.M.yyyy",
          "sms-FI": "d.M.yyyy",
          "sq-AL": "yyyy-MM-dd",
          "sr-Cyrl-BA": "d.M.yyyy",
          "sr-Cyrl-CS": "d.M.yyyy",
          "sr-Cyrl-ME": "d.M.yyyy",
          "sr-Cyrl-RS": "d.M.yyyy",
          "sr-Latn-BA": "d.M.yyyy",
          "sr-Latn-CS": "d.M.yyyy",
          "sr-Latn-ME": "d.M.yyyy",
          "sr-Latn-RS": "d.M.yyyy",
          "sv-FI": "d.M.yyyy",
          "sv-SE": "yyyy-MM-dd",
          "sw-KE": "M/d/yyyy",
          "syr-SY": "dd/MM/yyyy",
          "ta-IN": "dd-MM-yyyy",
          "te-IN": "dd-MM-yy",
          "tg-Cyrl-TJ": "dd.MM.yy",
          "th-TH": "d/M/yyyy",
          "tk-TM": "dd.MM.yy",
          "tn-ZA": "yyyy/MM/dd",
          "tr-TR": "dd.MM.yyyy",
          "tt-RU": "dd.MM.yyyy",
          "tzm-Latn-DZ": "dd-MM-yyyy",
          "ug-CN": "yyyy-M-d",
          "uk-UA": "dd.MM.yyyy",
          "ur-PK": "dd/MM/yyyy",
          "uz-Cyrl-UZ": "dd.MM.yyyy",
          "uz-Latn-UZ": "dd/MM yyyy",
          "vi-VN": "dd/MM/yyyy",
          "wo-SN": "dd/MM/yyyy",
          "xh-ZA": "yyyy/MM/dd",
          "yo-NG": "d/M/yyyy",
          "zh-CN": "yyyy/M/d",
          "zh-HK": "d/M/yyyy",
          "zh-MO": "d/M/yyyy",
          "zh-SG": "d/M/yyyy",
          "zh-TW": "yyyy/M/d",
          "zu-ZA": "yyyy/MM/dd",
        };
        return formats[Intl.DateTimeFormat().resolvedOptions().locale] || "a";
      }
}