import React, { useContext } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';

import TooltipCustom from '../../../../../../../general_components/TooltipCustom';
import { create_dropdowns_download_graph, generateName } from '../../../../../../../general_utils/functions';
import ListOfProductsContext from '../../../../../../../general_components/ListOfProductsContext';
import SelectionOfYearsForTheAverageCalculation from './SelectionOfYearsForTheAverageCalculation';

import styles from './YearlyGraphButtonGroup.module.scss';

function YearlyGraphButtonGroup({startYearForAverageCalculation, endYearForAverageCalculation, setStartYearForAverageCalculation, setEndYearForAverageCalculation, availableWaterYears, setProductName, catchmentName, productName}) {

    const listOfProducts = useContext(ListOfProductsContext);
    
      const createYearlyProductDropdownButtons = (arrayOfProducts) => {
        return arrayOfProducts.map((product, index) => {
          return <Dropdown.Item key={index} onClick={() => {
            setProductName(product)
          }}>{product.toUpperCase()}</Dropdown.Item>
        })
      }

    return (
        <div className={styles.YearlyGraphButtonGroup}>
                <SelectionOfYearsForTheAverageCalculation 
                    availableWaterYears={availableWaterYears}
                    startYearForAverageCalculation={startYearForAverageCalculation}
                    endYearForAverageCalculation={endYearForAverageCalculation}
                    setStartYearForAverageCalculation={setStartYearForAverageCalculation}
                    setEndYearForAverageCalculation={setEndYearForAverageCalculation}
                    productName={productName}
                />
            <ButtonGroup> &nbsp;
                <TooltipCustom
                    placement={'left'}
                    text={"Select the product to visualize."}>
                    <DropdownButton variant='graph' as={ButtonGroup} title={productName}>
                        {createYearlyProductDropdownButtons(listOfProducts)}
                    </DropdownButton>
                </TooltipCustom>
                <TooltipCustom
                    placement={'left'}
                    text={"Choose a format to download the graph."}>
                    <DropdownButton variant='graph' as={ButtonGroup} title={<FaDownload />}>
                        {create_dropdowns_download_graph(['PDF', 'PNG', 'JPEG', 'SVG'], 'yearlyGraph', generateName(catchmentName, [productName], 'wy', ''))}
                    </DropdownButton>
                </TooltipCustom>
            </ButtonGroup>
        </div>
    )
}

export default YearlyGraphButtonGroup;