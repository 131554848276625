import { LayersControl, MapContainer, TileLayer } from 'react-leaflet';

import { CenterMap, GeoJSONToComponent, splitGeoJsonIntoLayersPerProperty } from './utils/utils';
import { general_config } from '../../../../../../general_utils/general_configuration';

import styles from './CatchmentsMap.module.scss';

function CatchmentsMap({ catchmentsGeoJson, setCatchmentGeoJson, mapRef }) {
  let defaultCoordinates = [20, 0];
  let defaultZoom = 2;

  const addGeoJsonLayerToControl = (geoJson, name, index) => {
    return (
      <LayersControl.BaseLayer checked={index === 0} name={name} key={name}>
        <GeoJSONToComponent
          geoJson={geoJson}
          actionPerFeature={setCatchmentParamentersOnClick}
        />
      </LayersControl.BaseLayer>
    )
  };
  
  const setCatchmentParamentersOnClick = (feature, layer) => {
    layer.on('click', function (clickedCatchment) {
      setCatchmentGeoJson([feature]);
    })
  };

  return (
    <div className={styles.CatchmentsMapContainer}>
      <MapContainer
        ref={mapRef}
        center={defaultCoordinates}
        zoom={defaultZoom}
        className={styles.MapContainer}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <LayersControl position="topright">
          {
            splitGeoJsonIntoLayersPerProperty(catchmentsGeoJson, general_config.propertyForGeoJsonDivision).map((geoJson, index) => {
              let layerNameBasedOnProperty = geoJson[0].properties[general_config.propertyForGeoJsonDivision];
              return addGeoJsonLayerToControl(geoJson, layerNameBasedOnProperty, index)
            })
          }
        </LayersControl>
        <CenterMap 
          catchmentsGeoJson={catchmentsGeoJson} 
          defaultCoordinates={defaultCoordinates}
          defaultZoom={defaultZoom}
        />
      </MapContainer>
    </div>
  )
};

export default CatchmentsMap;