import { geoJson } from 'leaflet';

export const centerMapFromClick = (catchmentGeoJson, map_ref) => {
  const { current = {} } = map_ref;
  const map = current;
  map.fitBounds(geoJson(catchmentGeoJson).getBounds());
}

export const sort = ( a, b ) => {
    let titleA = a.props.children[0].props.children.props.children;
    let titleB = b.props.children[0].props.children.props.children;

    if ( titleA < titleB ){
      return -1;
    }
    if ( titleA > titleB ){
      return 1;
    }
    return 0;
  }