import React, { useState, useEffect } from 'react';
import { Button, Container, Navbar } from 'react-bootstrap';
import { GoGlobe } from 'react-icons/go';

import ContactParagraph from '../../../../general_components/ContactParagraph';
import GraphsRow from '../../../../general_components/GraphsRow';
import MultiFilterGraph from './components/MultiFilterGraph';
import NavBar from '../../../../general_components/NavBar';
import ListOfProductsContext from '../../../../general_components/ListOfProductsContext';
import TooltipCustom from '../../../../general_components/TooltipCustom';
import YearlyComparisonGraph from './components/YearlyComparisonGraph/YearlyComparisonGraph';

import { axiosCustom } from '../../../../general_utils/axios';
import { removeCatchmentItemsFromUserStorage } from'./utils/utils';
import { general_config } from '../../../../general_utils/general_configuration';

import styles from './GraphsPage.module.scss';

function GraphsPage({ catchmentGeoJson, setCatchmentGeoJson }) {

  const [listOfProducts, setListOfProducts] = useState([general_config.default_product]);

  const catchmentDisplayName = (catchmentGeoJson.length > 0 ? catchmentGeoJson[0].properties.display_name : '');
  const catchmentName = (catchmentGeoJson.length > 0 ? catchmentGeoJson[0].properties.name : '');
  const catchmentCountry = (catchmentGeoJson.length > 0 ? catchmentGeoJson[0].properties.country : '');
  const catchmentArea = (catchmentGeoJson.length > 0 ? (catchmentGeoJson[0].properties.area > 0 ? '. Area: ' + catchmentGeoJson[0].properties.area + ' km²' : ''):'');

  useEffect(() => {  
    if (catchmentName.length > 0) {
      axiosCustom.get(`catchmentProducts/${catchmentName}`)
      .then(res => {
        setListOfProducts(res.data[0].available_products);
      })
    }
    }, [catchmentName]);

  return (
    <>
      <NavBar>
        <TooltipCustom
          placement={'bottom'}
          text={"Back to catchment selection."}>
          <Button variant='wegaw-darkblue' onClick={()=> {removeCatchmentItemsFromUserStorage(); setCatchmentGeoJson(); }} size='sm'>
            <GoGlobe size={20} />
          </Button>
        </TooltipCustom>
        <Navbar>
          <strong>{catchmentDisplayName}{`, ${catchmentCountry}`}{` ${catchmentArea}`}</strong>
        </Navbar>
      </NavBar>
      <Container className={`mt-3 ${styles.graphsContainer}`}>
        <GraphsRow style={{backgroundColor: '#f1f3f4'}}>
          <ListOfProductsContext.Provider value={listOfProducts}>
            <YearlyComparisonGraph
              catchment={catchmentName}
              catchmentGeoJson={catchmentGeoJson}
            />
          </ListOfProductsContext.Provider>
        </GraphsRow>
        <GraphsRow className='mt-3'  style={{backgroundColor: '#f1f3f4'}}>
          <ListOfProductsContext.Provider value={listOfProducts}>
            <MultiFilterGraph
              catchment={catchmentName}
            />
          </ListOfProductsContext.Provider>
        </GraphsRow>
      </Container>
      <ContactParagraph/>
    </>
  )
}

export default GraphsPage;