import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import TooltipCustom from '../../../../../../../general_components/TooltipCustom';
import { FaInfoCircle } from 'react-icons/fa';

import styles from './SelectionOfYearsForTheAverageCalculation.module.scss';
import { useEffect } from 'react';

function  SelectionOfYearsForTheAverageCalculation( { availableWaterYears, startYearForAverageCalculation, endYearForAverageCalculation, setStartYearForAverageCalculation, setEndYearForAverageCalculation, productName }) {

  const handleStartSelection = (item) => {
    setStartYearForAverageCalculation(item.target.innerText);
  }

  const handleEndSelection = (year) => (event) => {
    setEndYearForAverageCalculation(year);
  }

  let availableWaterYearsSorted = [...availableWaterYears];
  availableWaterYearsSorted.sort((a, b) => a - b);

   useEffect(()=> {

    setStartYearForAverageCalculation(availableWaterYearsSorted[0] - 1);
    setEndYearForAverageCalculation(availableWaterYearsSorted[(availableWaterYearsSorted.length - 2)]);

   }, [availableWaterYears])

  return (
    <ButtonGroup className={styles.group}>
      <TooltipCustom
        placement={'left'}
        text={"Average is based on water years and this means it starts on Oct 1st. The start of 2021 and end in 2023 will show an average of dates from 2021-10-01 to 2023-09-30."}>
        <p style={{display:'flex', alignItems:'center', margin: '0px'}}><FaInfoCircle style={{ cursor: 'pointer' }}/></p>
      </TooltipCustom>
      <p className={styles.avgText}>&nbsp; Average from &nbsp;</p>
      <TooltipCustom
        placement={'left'}
        text={"Select the start of the average water years."}>
              <DropdownButton id="dropdown-item-button" title={startYearForAverageCalculation} variant="graph">
                  {availableWaterYearsSorted.map((year) => year!==availableWaterYearsSorted[availableWaterYearsSorted.length ] ? <Dropdown.Item key={year - 1} as="button" onClick={handleStartSelection}>{year - 1}</Dropdown.Item> : '')}
              </DropdownButton>
      </TooltipCustom>
      <p className={styles.avgText}>&nbsp; to &nbsp;</p>
      <TooltipCustom
        placement={'left'}
        text={"Select the end of the average water years."}>
              <DropdownButton id="dropdown-item-button" title={availableWaterYearsSorted[availableWaterYearsSorted.length - 1] === endYearForAverageCalculation ? 'Now' : endYearForAverageCalculation} variant="graph">
                  {availableWaterYearsSorted.map((year, index) => year > startYearForAverageCalculation ? <Dropdown.Item key={year} as="button" onClick={handleEndSelection(year)}>{availableWaterYearsSorted[availableWaterYearsSorted.length - 1] === year ? 'Now' : year}</Dropdown.Item> : '')}
              </DropdownButton>
      </TooltipCustom>
    </ButtonGroup>
  );
}

export default SelectionOfYearsForTheAverageCalculation;