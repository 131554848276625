import { Button, ListGroup, Offcanvas } from 'react-bootstrap';
import { FaBars, FaBook, FaInfoCircle, FaFileImage } from 'react-icons/fa';
import { MdOutlineDashboard } from 'react-icons/md';

import { general_config } from '../general_utils/general_configuration';

import styles from './Sidebar.module.scss';
import './Sidebar.scss';
import wegawLogo from '../images/wegaw-icon.png';

const iconSize = 25;

function Sidebar({ handleShow, setShowSidebar, handleClose, showSidebar }){
    return(
      <>
          <Button variant='wegaw-darkblue' onClick={() => handleShow(setShowSidebar)} >
            <FaBars size={20}/> 
          </Button>
          <Offcanvas 
            className={styles.offcanvas}
            show={showSidebar} 
            onHide={() => handleClose(setShowSidebar)} 
            placement={'start'}
          >
            <Offcanvas.Header closeButton>
              <img src={wegawLogo} width="40px" height="40px" alt="Wegaw Logo"/>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ListGroup variant='flush' >
                  <ListGroup.Item action className={styles.sidebar_row} href='/dashboard'>
                      <MdOutlineDashboard size={iconSize} className={styles.icon}/> <b>Dashboard</b>
                    </ListGroup.Item>
                    <ListGroup.Item action className={styles.sidebar_row} href='/rasters'>
                      <FaFileImage size={iconSize} className={styles.icon}/> <b>Rasters</b>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.sidebar_row} action 
                      href={general_config.baseURL + '/schema/redoc'}
                      target='_blank'
                      rel="noopener noreferrer">
                      <FaBook size={iconSize} className={styles.icon}/> <b>API Documentation</b>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.sidebar_row} action 
                      href='https://docs.wegaw.com/wegaw4hydro/wegaw-dashboard'
                      target='_blank'
                      rel="noopener noreferrer">
                      <FaInfoCircle size={iconSize} className={styles.icon}/> <b>Product Info</b>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.sidebar_row} action 
                      href='https://wegaw.com/'
                      target='_blank'
                      rel="noopener noreferrer">
                      <img src={wegawLogo} width={iconSize} alt="Wegaw Logo"/> <b>Wegaw.com</b>
                    </ListGroup.Item> 
                    <ListGroup.Item className={styles.poweredby}> 
                      Powered by <a className={styles.hyperlink} href='http://wegaw.com' 
                      target='_blank' rel="noopener noreferrer">Wegaw</a>
                    </ListGroup.Item>
                </ListGroup>
            </Offcanvas.Body>
          </Offcanvas>
      </>
    )
}

export default Sidebar;