import UserInformationStorage from '../../../../general_utils/userinformationstorage';

import '../MonitoringDataIssues.scss'


// So far it is not possible to change the format of the input, so we modify the date format of the column
// Issue opened on this topic: https://github.com/AllenFang/react-bootstrap-table/issues/833
export function dateFormatter(cell) {
    if (!cell) {
          return "";
    } else {
            const [year, month, day] = cell.split("-");

            return `${day}/${month}/${year}`;
    }
}

export const setCatchmentInformation = (catchment_name) => {
    let APIResponseCatchmentEndpoint = UserInformationStorage.get_user_information('APIResponseCatchmentEndpoint')
    APIResponseCatchmentEndpoint = JSON.parse(APIResponseCatchmentEndpoint)
    const catchmentGeoJson = APIResponseCatchmentEndpoint ? APIResponseCatchmentEndpoint.filter(catchment => catchment.properties.name === catchment_name) : []

    if (catchmentGeoJson.length > 0) {
        UserInformationStorage.set_user_information('catchmentGeoJson', JSON.stringify(catchmentGeoJson))
        UserInformationStorage.set_user_information('display_catchment', catchmentGeoJson[0].properties.display_name)
    } else {
        UserInformationStorage.remove_user_information('catchmentGeoJson')
        UserInformationStorage.remove_user_information('display_catchment')
    }
}