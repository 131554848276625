import {  Form } from 'react-bootstrap';

import { general_config } from '../../../general_utils/general_configuration';



export function loginUser({ username, password }) {
    return fetch(general_config.baseURL + '/token/', {
        method: 'POST',
        headers: {
            'Content-Type': "application/json",
        },
        body: JSON.stringify({
            username: username.value,
            password: password.value,
        })
    }).then(accessAndRefreshToken => accessAndRefreshToken.json())
};

export const WegawLogo = () => {
    return (
        <img
            src="https://solarimpulse.com/files/companies/logo/2021-04-12-131531/logoWegaw.jpg"
            width="120"
            height="70"
            className="d-inline-block align-top"
            alt="Wegaw logo"
        />
    )
};

export const FormInput = ({ loginInformation, id }) => {
    return(
        <Form.Group 
            className="mb-3" 
            controlId= {id}>
                <Form.Control 
                    type={loginInformation}
                    placeholder={loginInformation} 
                    name={loginInformation}
                    required 
                />
        </Form.Group>
    )
};