import { FaFileDownload } from "react-icons/fa";
import { Button } from "react-bootstrap";

import TooltipCustom from "./TooltipCustom";

import { createCsvHeaderFromProductsArray, createCsvBodyFromProductsAndDatesArrays, downloadCsv, postUserDownload, generateName} from "../general_utils/functions";

function DownloadCsvButton({ apiData, catchment, productNames, selectedTo, selectedFrom, buttonVariant }) {
    return (
    <TooltipCustom
        placement={'bottom'}
        text={"Download the data in csv format."}>
        <Button variant={buttonVariant} onClick={() => {
            if (productNames?.length > 0) {
            let fileName = generateName(catchment, productNames, selectedTo, selectedFrom)
            downloadCsv(
                fileName,
                createCsvHeaderFromProductsArray(productNames),
                createCsvBodyFromProductsAndDatesArrays(apiData.map(e => e.y), apiData.map(e => e.x))
            )
            postUserDownload(fileName);
            } else alert("No products selected")
        }}>
            <FaFileDownload/>
        </Button>
    </TooltipCustom>)
}

export default DownloadCsvButton;