export default class UserInformationStorage {

    static set_user_information(key, value){
        localStorage.setItem(key,value);
    }

    static get_user_information(key){
        return localStorage.getItem(key);
    }

    static remove_user_information(key){
        return localStorage.removeItem(key);
    }

    static clear_user_information(){
        return localStorage.clear();
    }

}