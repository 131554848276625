import { axiosCustom } from './axios';

export function validateToken(token) {
    try {
        return axiosCustom({
            method: 'POST',
            url: '/token/verify/',
            data: {
                token: token,
            }
        })
        .then(response => response.status === 200)

    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
};