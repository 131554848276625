import DateManager from "../../../../../../../general_utils/datemanager";

/**
 * The ploty graph needs to have all data in the same years in order to show it in the same x axe, so we
 * transform all data to 2004 and we hide the year.
 * 
 *  */ 
export const fuseDatesAndValuesInSingleYear = (dates, values, years_arr, water_period_start, maxYearToFuseData = 2004) => {
  const datemanager = new DateManager();
  let [arr_dates, arr_values] = [[],[]];
  const minYearToFuseData = maxYearToFuseData - 1;
  for (let year of years_arr) {
      let [yearly_dates, yearly_values] = [[],[]];
      const start_date = new Date(year - 1 + "-" + water_period_start);
      const end_date = new Date(year + "-" + water_period_start);
      dates?.forEach((day, index)=> {
        let currentUTCDay = new Date(day);
        let dayInDatetimeFormat = datemanager.dateUTCAdjustment(currentUTCDay)
          if (DateManager.isDateAmongDates(dayInDatetimeFormat, start_date, end_date)) {
              if (dayInDatetimeFormat.getFullYear() === year) {
                yearly_dates.push(DateManager.replaceYearFromStrDate(day, String(maxYearToFuseData)))
              } else {
                yearly_dates.push(DateManager.replaceYearFromStrDate(day, String(minYearToFuseData)));
              }
              yearly_values.push(values[index]);
          }
      })
      if (yearly_dates?.length > 0 && yearly_values?.length > 0){
        arr_dates.push(yearly_dates);
        arr_values.push(yearly_values);
    }
  }
  return [arr_dates, arr_values];
}

export function getUniqueSortedYearsFromDate(datesArray, compareDate="10-01") {
  const [compareMonth, compareDay] = compareDate.split("-").map(Number);
  const years = datesArray.map(date => {
      const [year, month, day] = date.split("-").map(Number);
      return (month > compareMonth || (month === compareMonth && day >= compareDay)) ? year : year - 1;
  });

  const uniqueYears = Array.from(new Set(years));
  return uniqueYears.sort((a, b) => a - b);
}