/**
 * @description Creating a axios custom, which will be the base of the interceptor.
 * The interceptor will have a request and a response configuration.
 * 
 * @request:
 *  Get the token from the local storage and, when the method is get, creates the authorization header.
 *  Moreover, it creates the COntent-Type and Access-Control-Allow-Origin headers, in order to avoid CORS.
 * 
 * @response:
 *  The response performs different acctions depending on the response input.
 *      1.If the response is okay, it just return it
 *      2.If the response has status 401 and came from the token request page, there is a problem so it reject the promise to avoid a loop
 *      3.If the response is 401 and it is the first time it gets an error, it refresh the token using the refreshtoken from the user Storage 
 *      and set the updated token to the user Storage. Finally, it send again the request (if it is a verify token request, it will send the new 
 *      call as the token is part of the body and it is needed to use the new one).
 *      3.1.If this request fails again, it may mean that the refresh token is already exhausted (by default it has a valid period of one day),
 *      it cleans the data and brings the user back to the login page, cleaning all the data stored in user Storage.
 * 
 */

import axios from 'axios';

import UserInformationStorage from './userinformationstorage';
import { general_config } from './general_configuration';

const axiosCustom = axios.create({ baseURL: general_config.baseURL });

axiosCustom.interceptors.request.use(request => {

    const token = UserInformationStorage.get_user_information('token');
    if (token) {
        request.headers['Authorization'] = "Bearer " + token;
    }
    return request;
    }, error => {
        Promise.reject(error);
    }
)

axiosCustom.interceptors.response.use( response => {
        return response;
    }, async function (error) { // block to handle error case
        const originalRequest = error.config;

        if (error.request.status === 401 && originalRequest.url ===
            'token/' ) {
            // Added this condition to avoid infinite loop 
            // Redirect to any unauthorised route to avoid infinite loop...
            return Promise.reject(error);
        }

        if (error.response.status === 401  && !originalRequest._retry) { // Code inside this block will refresh the auth token
            originalRequest._retry = true;
            const refresh_token = UserInformationStorage.get_user_information('refreshToken'); 
            return axios.post(general_config.baseURL + '/token/refresh/',
                {
                    'refresh': refresh_token
                })
                .then( res => {
                    if (res.status === 200) {  
                        UserInformationStorage.set_user_information('token', res.data.access);

                        if (originalRequest.url === '/token/verify/') {
                            return axiosCustom({
                                method: originalRequest.method,
                                url: originalRequest.url,
                                data: {
                                    token: res.data.access,
                                }
                            })
                        } else {
                            return axiosCustom(originalRequest);
                        }
                    }   
                })
                .catch(function (error) { // block to handle error case
                    if (error.response.status === 401) {
                        UserInformationStorage.clear_user_information();
                        window.location.href = window.location.origin + '/';
                        return Promise.reject(error);
                        // If no auth here means that the refresh token is exausted, so this will trigger the login (no user Storage and isToken == false)
                    } else {
                        return Promise.reject(error);
                    }
                }) 
        }
    });

export {axiosCustom};