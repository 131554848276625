import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import UserInformationStorage from '../../general_utils/userinformationstorage';

import styles from './Logout.module.scss';

const HOMEPAGE = '/';
const MILISECONDSBEFOREBACKTOLOGIN = 5000;
const redirectToLogin = () => window.location.href = HOMEPAGE;

function Logout() {

  const [seconds, setSeconds] = useState(MILISECONDSBEFOREBACKTOLOGIN/1000);
  let timeoutId;

  useEffect(() => {
    // Countdown function
    const countdown = () => {
      setSeconds((prevSeconds) => {
        return prevSeconds - 1;
      });
    };

    timeoutId = window.setTimeout(()=>redirectToLogin(), MILISECONDSBEFOREBACKTOLOGIN);

    // Start the countdown timer
    const timerId = setInterval(countdown, 1000);

    // Clean up the interval when the component unmounts
    return () => { clearInterval(timerId); clearTimeout(timeoutId)}
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (
    <div
      className='text-center jumbotron d-flex align-items-center justify-content-center min-vh-100'>
      <div className={styles.logout}>
        <h1
          className={`mb-4 ${styles.title}`}>
          You have successfully logged out.
        </h1>
        <h3
          className={`mb-4 ${styles.title}`}>
          Sign in again to continue working.
        </h3>
        <Link to={HOMEPAGE}>
          {UserInformationStorage.clear_user_information()}
          <Button
            variant='wegaw-blue'
            onClick={clearTimeout(timeoutId)}
            >
            Return to login
          </Button>
        </Link> 
        <p className={`mt-4 ${styles.title}`}>Automatically returning to login in {seconds >= 0 ? seconds : 0} seconds.</p>
      </div>
    </div>
  )
};

export default Logout;