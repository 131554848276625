import { general_config } from '../../../../../../../general_utils/general_configuration';
import Gradient  from "javascript-color-gradient";

export const graphConfiguration = () => {
  return {
      modeBarButtonsToRemove: ['autoScale2d', 'select2d', 'lasso2d', 'toImage']
  }
};

export const graphLayout = (productName) => {
  return {
    plot_bgcolor:"#f1f3f4",
    paper_bgcolor:"#f1f3f4",
    hovermode: 'x unified',
    font: {
      family: "'Saira',Helvetica,Arial,Lucida,sans-serif"
    },
    title: {
      text: productName.toUpperCase() + " YEARLY COMPARISON<br><sup>Comparison of "+ productName + " data over the selected water years</sup>",
      font: {
        color: '#000000',
      }
    },
    xaxis: {
      gridcolor: '#ffffff',
      autorange: true,
      tickformat: '%b %d',
      hoverformat: '%b %d',
      type: "date",
    },
    yaxis: {
      title: productName.toUpperCase() + " DATA IN " + general_config['products'][productName.toUpperCase()].productMeasure.toUpperCase(),
      gridcolor: '#ffffff',
    },
    updatemenus: [{
      active: 2,
      type : "buttons",
      direction : "left",
      buttons: [
        {
          label: 'Show all',
          method: 'update',
          args: [{'visible': ['']}] // Toggle visibility of trace 1
        },
        {
          label: 'Hide all',
          method: 'update',
          args: [{'visible': ['legendonly']}] // Toggle visibility of trace 1
        },
      ],
      x: 1.1,
      xanchor: 'left',
      y: 1.2, // Change the y value to move the dropdown to the top
      yanchor: 'top', // Change the yanchor to 'top'
      // Styling for the dropdown button
      style: {
          // Add your styling properties here
          border: '1px solid #ddd',
          backgroundColor: '#f9f9f9',
          borderRadius: '5px',
          padding: '5px'
      }
    }]
  }
}

export class YearlyGraphDataManager {

  static selectedDataToPlotyFormat = (selectedYears, selectedData, numberOfTotalYearsAvailable, productName) => {
    let colors = generateColors(numberOfTotalYearsAvailable);
    let traces = [];
  
    for(let i = 0; i < selectedYears.length; i++) {
      traces.push(
        {
          x: selectedData[0][i],
          y: selectedData[1][i],
          name: 'WY ' + (selectedYears[i] - 1) + " - " + selectedYears[i],
          type: 'scatter',
          mode: (productName.toLowerCase() === 'ms' ? 'lines+markers' : 'lines'),
          line: {
            width: 2,
            dash: (productName.toLowerCase() === 'ms' ? 'dot' : '')
          },
          marker: { color: colors[i], size: 5 },
          visible: (i === 0 ? '': 'legendonly'),
          showlegend: true,
        }
      )
    }
    return traces
  }
}

export const generateColors = (numberOfMidpoints) => new Gradient()
  .setColorGradient( "#0A3873", "#4962F2", "#C84AD9", "#F2AE72")
  .setMidpoint(numberOfMidpoints)
  .getColors();