import styles from './ContactParagraph.module.scss'; 

function ContactParagraph(){

    return(   
        <div className={styles.footer}>
            <div className={styles.contact_paragraph_container}>
                Any question? &nbsp;<a className={styles.hyperlink} href='https://wegaw.com/contact-us/' target='_blank' rel="noopener noreferrer">Contact our team</a> 
            </div>
        </div>
    )
}

export default ContactParagraph;