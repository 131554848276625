import { Dropdown, NavDropdown } from 'react-bootstrap';
import { FaSignOutAlt, FaUser } from 'react-icons/fa';

import TooltipCustom from './TooltipCustom';

import styles from './ProfileSidebar.module.scss';
import UserInformationStorage from '../general_utils/userinformationstorage';
import './ProfileSidebar.scss';

function ProfileSidebar() {

    return (
        <TooltipCustom
            placement={'bottom'}
            text={"Account information."}>
            <NavDropdown
                title={
                        <FaUser size={20} className={`navbar_horizontal_margin ${styles.profile_icon}`}/>
                }
                drop={'start'}
                id="usericon"
            >
                <Dropdown.ItemText>
                    <b>
                        {UserInformationStorage.get_user_information('user')}
                    </b>
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                        <a href='/downloads'>
                            Latest downloads
                        </a>
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                        <a href='https://wegaw.com/contact-us/'
                            target='_blank'
                            rel="noopener noreferrer">
                            Contact Us
                        </a>
                </Dropdown.ItemText>
                <Dropdown.Divider />
                <Dropdown.ItemText>
                        <a href={'/logout'}>
                            <FaSignOutAlt className={styles.signout_icon} size={20} />
                        </a>
                </Dropdown.ItemText>
            </NavDropdown>
        </TooltipCustom>
    )
}

export default ProfileSidebar;