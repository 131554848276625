import { useEffect, useState, useMemo } from 'react';
import { Button, Container } from 'react-bootstrap';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

import { setCatchmentInformation } from './utils/utils';
import { axiosCustom } from '../../../general_utils/axios';
import { general_config } from '../../../general_utils/general_configuration';
import DateManager from '../../../general_utils/datemanager';
import ContactParagraph from '../../../general_components/ContactParagraph';
import NavBar from '../../../general_components/NavBar';

import './MonitoringDataIssues.scss';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const MONITORINGENDPOINT = general_config.api.monitoringEndpoint;

function MonitorningDataIssues() {

    const [completeRowData, setCompleteRowData] = useState([]); // To storage the whole dataset of issuess and avoid a call everytime showOnlyNonAcknowledgeIssues changes
    const [filteredRowData, setFilteredRowData] = useState([]);
    const [showOnlyNonAcknowlegedIssues, setShowOnlyNonAcknowledgedIssues] = useState(true);
    /**
     * Filters by default are only allowed at ag grid by the enterprise option.
     * This states allows to show, by default, only the non acknowleged issues,
     * and allows the user to change it with a button in the navbar.
     */

    const retriveApiMonitoringIssues = () => {
            axiosCustom.get(MONITORINGENDPOINT).then(issues => {
                let data = issues.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setCompleteRowData(data);
                setFilteredRowData((showOnlyNonAcknowlegedIssues ? data.filter(item => !item.acknowledge) : data));
            }).catch(error => console.error(error));
    }

    const modifyAcknowledgeViaApi = (p) => {
        let element_id = p.data.id;
            axiosCustom.put(MONITORINGENDPOINT + element_id + "/")
            .then(response => {
                if (!response || response.status !== 200) {
                    retriveApiMonitoringIssues();
                    alert("Update not sucessful. Refreshing data");
                }
            }).catch(error => console.error(error));
        }

    const goToPage = (page) => {
        window.location.href = page;
    };

    const columnDefs = [
        {
            filter: 'agTextColumnFilter',
            headerName: 'Catchment',
            field: "catchment_name",
            onCellClicked: (event) => { setCatchmentInformation(event.data.catchment_name); goToPage("/dashboard"); },
            cellRenderer: p => <b style={{cursor:'pointer'}}>{p.data.catchment_name}</b>
        },
        { filter: 'agTextColumnFilter', headerName: 'Product name', field: "product" },
        {
            filter: 'agDateColumnFilter',
            headerName: 'Date',
            field: 'date',
            sortable: true,
            cellRenderer: p => DateManager.dateToLocalDateString(p.data.date),
        },
        { filter: 'agTextColumnFilter', headerName: 'Type', field: "type" },
        {
            headerName: 'Acknowledge',
            field: "acknowledge",
            cellRendererParams: { disabled: false },
            onCellValueChanged: p => modifyAcknowledgeViaApi(p),
            filter: true,
            cellClass: 'cellCenter'

        }];

    const defaultColDef = useMemo(() => {
        return {
            floatingFilter: true,
            flex: 1,
            resizable: false,
        }
    }, []);

    useEffect(() => {
        retriveApiMonitoringIssues();
    }, []);

    useEffect(() => {
        setFilteredRowData((showOnlyNonAcknowlegedIssues ? completeRowData.filter(item => !item.acknowledge) : completeRowData));
    }, [showOnlyNonAcknowlegedIssues]);

    return (
        <>
            <NavBar>
                <Button variant='wegaw-darkblue' onClick={() => setShowOnlyNonAcknowledgedIssues(!showOnlyNonAcknowlegedIssues)}>
                    {(showOnlyNonAcknowlegedIssues ? 'Show all issues' : 'Show non acknowledge issues')}
                </Button>
            </NavBar>
            <Container className={`text-center mt-3 mb-3 tableContainer`}>
                <div className="ag-theme-quartz">
                    <AgGridReact
                        rowData={filteredRowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                        domLayout={'autoHeight'}
                    />
                </div>
            </Container>
            <ContactParagraph/>
        </>
    )
}

export default MonitorningDataIssues;