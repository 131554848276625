import './Loading.scss';

function Loading() {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
};
  
export default Loading;