import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function TooltipCustom({ placement, text, children }) {
    
    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Tooltip>
                    {text}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    )
}

export default TooltipCustom;