import { GeoJSON, useMap } from 'react-leaflet';
import { geoJson as leafletGeoJson} from 'leaflet';

export const CenterMap = ({ catchmentsGeoJson, defaultCoordinates, defaultZoom }) => {
  const map = useMap()
  const catchmentsGeoJsonArray = catchmentsGeoJson
  if (catchmentsGeoJsonArray) {
    map.fitBounds(leafletGeoJson(catchmentsGeoJsonArray).getBounds())
  } else {
    map.flyTo(defaultCoordinates, defaultZoom);
  }
};

const isValueInArray = (arr, val) => arr.includes(val);

const getUniqueValuesFromDictProperty = (dict, property) => {
  let groups = []
  dict?.forEach(catchment => {
    if (!isValueInArray(groups, catchment.properties[property])) groups.push(catchment.properties[property])
  })
  return groups
};

export const splitGeoJsonIntoLayersPerProperty = (geoJson, property) => {
  let uniquePropertyValues = getUniqueValuesFromDictProperty(geoJson, property);
  let geojsonOfProperty = [];
  uniquePropertyValues?.forEach(val => {
    geojsonOfProperty.push(geoJson?.filter(catchment => {
      return (catchment.properties[property] === val ? catchment : null)
    }));
  })
  return geojsonOfProperty;
};

export const GeoJSONToComponent = ({ geoJson, actionPerFeature }) => {
  return ( geoJson ? (<GeoJSON data={geoJson} onEachFeature={actionPerFeature}/>) : null)
};