export const general_config = {
    api: { monitoringEndpoint: '/admin/monitoring/' },
    baseURL: window?.appConfig?.BASE_URL,
    default_product: 'SWE',
    products : {   
        SWE: {
            productMeasure:'cm',
            productColor:'#1b64b3'
        },
        SCE: {
            productMeasure:'km2',
            productColor:'#DD16FA'
        },
        HS: {
            productMeasure:'cm',
            productColor:'#9623F7'
        },
        SWC: {
            productMeasure:'cm3 water /m3 soil',
            productColor:'#0CFA90'
        },
        MS: {
            productMeasure:'km2',
            productColor:'#41106b'
        },
        PP: {
            productMeasure:'mm',
            productColor:'#1639FA'
        },
        SWEVOLUME: {
            productMeasure:'Mio m3',
            productColor:'#18AD63'
        },
    },
    propertyForGeoJsonDivision: "category",
};