import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import { validateToken } from "../../general_utils/validateToken";
import UserInformationStorage from "../../general_utils/userinformationstorage";

const loginPage = '/';

// children refers to webpage data
function PrivateRoute({ children }) {

    const [isValidToken, setIsValidToken] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = UserInformationStorage.get_user_information('token');

        const checkTokenValidity = async () => {
            try {
                if (token){
                    const isValid = await validateToken(token);
                    if (isValid) {
                        setIsValidToken(isValid);
                    } else {
                        UserInformationStorage.clear_user_information();
                        navigate(loginPage);
                    }
                } else {
                    UserInformationStorage.clear_user_information();
                    navigate(loginPage);
                }
            } catch (error) {
                console.error('Error during token validation:', error);
                UserInformationStorage.clear_user_information();
                navigate(loginPage);
            }
        };

        checkTokenValidity();
    
    }, [navigate]);

    if (isValidToken){
        return children;
     };

};

export default PrivateRoute;